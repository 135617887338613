import { gql } from '@apollo/client'

export const ERROR_ATTRIBUTES = gql`
  fragment errorAttributes on Error {
    error
    message
    backtrace
  }
`

export const CUSTOMER_SHORT_ATTRIBUTES = gql`
  fragment customerShortAttributes on Customer {
    id
    email
    fullName
  }
`

export const JOB_SHORT_ATTRIBUTES = gql`
  fragment jobShortAttributes on Job {
    id
    name
    studio {
      id
      name
      logoUrl
    }
  }
`

export const LAB_SHORT_ATTRIBUTES = gql`
  fragment labShortAttributes on Lab {
    id
    name
    key
  }
`

export const STUDIO_SHORT_ATTRIBUTES = gql`
  fragment studioShortAttributes on Studio {
    id
    name
    email
    logoUrl
    npsEnabled
    reportingCode
  }
`

export const USER_SHORT_ATTRIBUTES = gql`
  fragment userShortAttributes on User {
    id
    email
    fullName
  }
`
export const ADDRESS_ATTRIBUTES = gql`
  fragment addressAttributes on Address {
    id
    recipient
    line1
    line2
    city
    state
    zip
    skipVerification
  }
`

export const CUSTOMER_ATTRIBUTES = gql`
  fragment customerAttributes on Customer {
    id
    fullName
    firstName
    lastName
    email
    phone
    ordersCount
    createdAt
    deletedAt
    cartId
  }
`

export const GIFT_CARD_ATTRIBUTES = gql`
  fragment giftCardAttributes on GiftCard {
    id
    num
    email
    phone
    amountCents
    remainingAmountCents
    subjectDetails
    photodayInitialProfitCents
    studioInitialProfitCents
    stripeProfitCents
    refundable
    payoutId
    purchasedAt
    updatedAt
    createdAt
    expiration
    studio {
      ...studioShortAttributes
    }
    lab {
      ...labShortAttributes
    }
    customer {
      ...customerShortAttributes
    }
    job {
      ...jobShortAttributes
    }
  }
  ${STUDIO_SHORT_ATTRIBUTES}
  ${LAB_SHORT_ATTRIBUTES}
  ${CUSTOMER_SHORT_ATTRIBUTES}
  ${JOB_SHORT_ATTRIBUTES}
`

export const JOB_ATTRIBUTES = gql`
  fragment jobAttributes on Job {
    id
    name
    date
    accessMode
    accessCode
    estimatedSubjectsCount
    openedAt
    expiresAt
    publishedDate
    autoSmsEnabled
    access
    createdAt
    lastPublishedAt
    yearbookSelectionEnabled
    yearbookSelectionDeadline
    studio {
      ...studioShortAttributes
    }
    bulkShipOrder {
      id
      expiresAt
      notes
      address {
        recipient
        line1
        line2
        city
        state
        zip
      }
    }
  }
  ${STUDIO_SHORT_ATTRIBUTES}
`

export const FILE_ATTRIBUTES = gql`
  fragment fileAttributes on File {
    filename
    blob
  }
`

export const LAB_ATTRIBUTES = gql`
  fragment labAttributes on Lab {
    id
    name
    phone
    key
    shippingIntegrationType
    logoUrl
    contactUrl
    websiteUrl
    payoutMethod
    createdAt
    publishedAt
  }
`

export const PAYOUT_ATTRIBUTES = gql`
  fragment payoutAttributes on Payout {
    id
    labCents
    studioCents
    photodayCents
    stripeCents
    stripeLabTransfer
    stripeStudioTransfer
    payoutsComplete
    totalCents
    purchasableType
    purchasable {
      id
      num
      customer {
        ...customerShortAttributes
      }
      studio {
        ...studioShortAttributes
      }
      lab {
        ...labShortAttributes
      }
    }
  }
  ${CUSTOMER_SHORT_ATTRIBUTES}
  ${STUDIO_SHORT_ATTRIBUTES}
  ${LAB_SHORT_ATTRIBUTES}
`

export const ORDER_ATTRIBUTES = gql`
  fragment orderAttributes on Order {
    id
    num
    email
    phone
    status
    subtotal
    shipping
    taxesAmount
    total
    createdAt
    purchasedAt
    sentAt
    taxDetails
    taxInformation
    trackingUrl
    refundable
    payoutId
    totalCents
    shippingRateId
    shippingOptions {
      id
      description
    }
    address {
      ...addressAttributes
    }
    studio {
      ...studioShortAttributes
    }
    lab {
      ...labShortAttributes
    }
    job {
      ...jobShortAttributes
    }
    customer {
      ...customerAttributes
    }
    payout {
      ...payoutAttributes
    }
    reprintRequest {
      id
      requestType
      status
    }
    bulkShipOrderId
  }
  ${ADDRESS_ATTRIBUTES}
  ${STUDIO_SHORT_ATTRIBUTES}
  ${LAB_SHORT_ATTRIBUTES}
  ${JOB_SHORT_ATTRIBUTES}
  ${PAYOUT_ATTRIBUTES}
  ${CUSTOMER_ATTRIBUTES}
`

export const REFUND_ATTRIBUTES = gql`
  fragment refundAttributes on Refund {
    id
    partial
    stripeId
    autoProcess
    amountCents
    completedAt
    failedAt
    failureMessage
  }
`
export const REVERSAL_ATTRIBUTES = gql`
  fragment reversalAttributes on Reversal {
    id
    partial
    targetType
    targetId
    stripeId
    autoProcess
    amountCents
    completedAt
    failedAt
    failureMessage
  }
`

export const REFUND_GROUP_ATTRIBUTES = gql`
  fragment refundGroupAttributes on RefundGroup {
    id
    reason
    regarding
    notes
    refundApplicationFee
    createdAt
    refund {
      ...refundAttributes
    }
    payout {
      ...payoutAttributes
    }
    customer {
      ...customerShortAttributes
    }
    user {
      ...userShortAttributes
    }
    reversals {
      ...reversalAttributes
    }
  }
  ${REFUND_ATTRIBUTES}
  ${REVERSAL_ATTRIBUTES}
  ${PAYOUT_ATTRIBUTES}
  ${CUSTOMER_SHORT_ATTRIBUTES}
  ${USER_SHORT_ATTRIBUTES}
`

export const STUDIO_ATTRIBUTES = gql`
  fragment studioAttributes on Studio {
    id
    name
    logoUrl
    creditCents
    phone
    pdFee
    email
    npsEnabled
    intercomEnabled
    salesforceSync
    stripeMigrationStatus
    allowKnockouts
    studioSlug
    owner {
      id
      fullName
    }
    primaryLab {
      id
      name
    }
    createdAt
    reportingCode
  }
`

export const SUPPORT_REQUEST_ATTRIBUTES = gql`
  fragment supportRequestAttributes on SupportRequest {
    id
    requestType
    status
    createdAt
    createdAgo
    completedAt
    completedIn
    studio {
      id
      name
      owner {
        id
        fullName
      }
    }
    user {
      id
      fullName
    }
  }
`

export const SUPPORT_REQUEST_LIST_REPRINT_ATTRIBUTES = gql`
  fragment supportRequestReprintAttributes on SupportRequest {
    ...supportRequestAttributes
    orderReprint {
      id
      num
      trackingNumber
      partial
      reason
      extraInfo
      order {
        id
        num
        phone
        trackingUrl
        shippingOptions {
          id
          bulk
        }
        lab {
          id
          name
          key
        }
      }
    }
  }
  ${SUPPORT_REQUEST_ATTRIBUTES}
`

export const SUPPORT_REQUEST_REPRINT_ATTRIBUTES = gql`
  fragment supportRequestReprintAttributes on SupportRequest {
    ...supportRequestAttributes
    orderReprint {
      id
      num
      reason
      extraInfo
      trackingNumber
      carrier
      carrierService
      colorCorrection
      partial
      addressId
      updatedAt
      address {
        ...addressAttributes
      }
      orderReprintProducts {
        orderItemProductId
        id
      }
      order {
        id
        num
        email
        phone
        address {
          ...addressAttributes
        }
        orderItems {
          id
          name
          priceSheetItem {
            priceSheetItemType
          }
          description
          quantity
          orderItemProducts {
            id
            name
            fullName
            digitalDownload
            orderItemProductNodes {
              id
              layoutNode {
                nodeKey
                description
              }
              photo {
                imageFileName
                id
                retouchedImageFileName
              }
              previewImageUrls {
                small
                medium
                large
              }
              value
              photoId
            }
            product {
              id
              description
              imageFileName
              imageUrl
            }
          }
        }
        customer {
          id
        }
        trackingUrl
        shippingOptions {
          id
          rateCents
          description
          rate
          minimumAmountCents
          maximumAmountCents
          bulk
        }
        shippingRateId
        shippingRate {
          id
          rateCents
          description
          rate
          minimumAmountCents
          maximumAmountCents
        }
        lab {
          id
          name
          key
        }
        subtotalCents
      }
    }
  }
  ${ADDRESS_ATTRIBUTES}
  ${SUPPORT_REQUEST_ATTRIBUTES}
`

export const SUPPORT_REQUEST_SMS_CAMPAIGN_ATTRIBUTES = gql`
  fragment supportRequestSmsCampaignAttributes on SupportRequest {
    ...supportRequestAttributes
    studioPromotion {
      id
      startAt
      endAt
      offer {
        id
        code
      }
      promotion {
        id
        name
      }
      studioPromotionJobs {
        id
        job {
          id
          name
        }
      }
    }
  }
  ${SUPPORT_REQUEST_ATTRIBUTES}
`

export const SUPPORT_REQUEST_EVENT_ATTRIBUTES = gql`
  fragment supportRequestEventAttributes on SupportRequestEvent {
    id
    eventType
    status
    comment
    createdAt
    user {
      id
      fullName
    }
  }
`

export const USER_ATTRIBUTES = gql`
  fragment userAttributes on User {
    id
    fullName
    firstName
    lastName
    phone
    email
    verified
    isPhotoday
    isSuperAdmin
    createdAt
    updatedAt
    adminRoles
    intercomEnabled
    salesforceSync
    studio {
      ...studioShortAttributes
    }
  }
  ${STUDIO_SHORT_ATTRIBUTES}
`
